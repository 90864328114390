@font-face {
  font-family: "troika";
  src: url("./Assets/Font/troika.otf") format("woff2");
  /* Add additional font formats if necessary */
}
#canvas {
  border: 3px black solid;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: -50% -50%; */
  background-repeat: no-repeat;
  background-size: cover;
}
.game-over {
  background: url("game over screen.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.game-on {
  background: url("background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.App {
  background: url("appBackgorund.png");
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  user-select: none;
  font-family: "troika" !important;
}
body {
  color: white;

  background: url("background.png");
  background-size: 140%;
}

#footer {
  text-align: center;
  background-color: #fff;
  padding: 0.5em;
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-left: -12px;

  & a {
    margin: 0 24px;
  }

  & a,
  & a img {
    display: inline-block;
  }

  & a img {
    max-width: 100%;
  }
}

.full-height {
  position: relative;
  height: calc(100vh - 48px);
  overflow: auto;
}

.form-control:focus {
  border-color: #252525;
  box-shadow: 0 0 0 0.25rem rgba(149, 20, 20, 0.25);
}

.small-logo {
  width: 150px;
  height: 90px;
  margin-top: 10px;
  margin-left: -5px;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.outline {
  color: #252525;
}

.btn-light {
  color: #252525;

  font-weight: bold;
  text-transform: uppercase;
  width: 70%;
  margin-left: 15%;
  border-radius: 10px;
  font-size: 1.3rem;

  &:disabled,
  &.disabled,
  &:active,
  &:hover {
    color: #252525 !important;
    background-color: white !important;
  }
}

#app-container {
  position: relative;
}

#app-container::before {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  filter: brightness(60%);
  content: "";
  background-image: url("./Assets/Background/pozadine-upitnik-06.jpg");
  background-size: contain;

  margin-left: -12px;

  /* background-color: #f8f8f8; */
}
