/* .float-start:last-child {
  margin-left: 1rem;
}

#logo {
  display: flex;
  margin: 0 auto;
  width: 50%;
  height: 50%;
}

#logo img {
  max-width: 100%;
  overflow: hidden;
}

.home-buttons {
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-direction: column;
} */
.instruction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding-top: 20%;
}

.centered-content {
  text-align: center;
}

#instruction img {
  max-width: 100%;
  max-height: 100%;
}

.instruction-btn {
  bottom: 20px;
  width: 100px;
}
