.btn-custom {
  padding: 8px 16px;
  border: 2px solid #7a7a7a;
  background-color: #d2d2d2;
  color: #007bff;
  font-size: 16px;
  touch-action: manipulation; /* Disable long-press menu on mobile */
  -webkit-user-select: none; /* Disable text selection */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.button {
  width: 33vw;
  height: 15vh;
}

.btn-custom-left {
  border-top-left-radius: 20px; /* Adjust the value to change the degree of rounding */
  border-bottom-left-radius: 20px; /* Adjust the value to change the degree of rounding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-custom-right {
  border-top-left-radius: 0; /* Adjust the value to change the degree of rounding */
  border-bottom-left-radius: 0; /* Adjust the value to change the degree of rounding */
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contolls {
  width: 350px;
  height: 17vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
