.float-start:last-child {
  margin-left: 1rem;
}

#logo {
  display: flex;
  margin: 0 auto;
  width: 50%;
  height: 50%;
}

#logo img {
  max-width: 100%;
  overflow: hidden;
}

.home-buttons {
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-direction: column;
}
#tis {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  justify-content: center;
}
#tis img {
  max-width: 100%;
  overflow: hidden;
}
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
