.hideButton {
  display: none;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: -25px;
}

.custom-table > thead > tr > th {
  color: white;
  background: none;
  border: none;
}
.custom-table > tbody > tr > td {
  color: white;
  background: none;
  border: none;
}
.custom-table t {
  color: white;
  background: none;
  border: none;
}
.custom-table thead,
.custom-table tbody,
.custom-table tfoot,
.custom-table tr,
.custom-table td,
.custom-table th {
  background: none;
  /* border-color: #252525;
  background: white; */
}

.custom-table .table-cell-email {
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.score-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 5%;
  padding-right: 5%;
}

.refresh {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.score-content {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
